export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

export function isAndroid() {
  var u = navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
}

export function isiOS() {
  var u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

export function isWeixin() {
  var u = navigator.userAgent;
  return (
    u.match(/MicroMessenger/i) &&
    u
      .match(/MicroMessenger/i)
      .toString()
      .toLowerCase() == 'micromessenger'
  );
}

/**
 * 判断是否安装app
 * @url ios,android 提供（打开app的链接）
 * @callback （未安装app的回调）
 * */
export function checkOpen(url, callback) {
  let hasApp = true,
    t = 1000,
    t1 = Date.now(),
    ifr = document.createElement('iframe');
  setTimeout(function () {
    if (!hasApp) {
      callback && callback();
    }
    document.body.removeChild(ifr);
  }, 2000);

  ifr.setAttribute('src', url);
  ifr.setAttribute('style', 'display:none');
  document.body.appendChild(ifr);

  setTimeout(function () {
    //启动app时间较长处理
    let t2 = Date.now();
    if (t2 - t1 < t + 100) {
      hasApp = false;
    }
  }, t);
}
