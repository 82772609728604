<script setup>
import { goNextWebPage } from '@/assets/js/jsbridge';
const handelLink = (url) => {
  goNextWebPage({
    url: `${process.env.VUE_APP_URL}/agreement${url}`,
    title: url === '/user' ? '用户协议' : '隐私政策',
    titleIsTransparent: false,
  });
};
</script>
<template>
  <div class="welcome">
    <p>
      为了更好地保障您的个人权益，请您使用亲Key键盘前，请务必审读<a
        @click="handelLink('/user')"
        >《用户协议》</a
      >与<a @click="handelLink('/privacy')">《隐私政策》</a
      >，以帮助您了解我们为您提供的服务、我们如何处理个人信息以及您享有的权利。点击“同意”按钮，表示您已知情，并同意以上协议和以下约定:
    </p>
    <p>
      1.为了保障软件的安全运行和账户安全，我们会申请收集您的设备信息、IP地址、MAC地址、IMEI、已安装的应用列表等。
    </p>
    <p>
      2.我们提供了图片文字浏览的基本功能，在基本功能模式下，我们将仅收集部分无法识别您个人身份的设备参数及系统信息，不会收集设备Mac地址、国际移动设备识别码(如:AndroidID/IMEI)、唯一设备识别符(如:IDFA/OAID)等可能识别您个人身份的设备信息及其他个人信息。
    </p>
    <p>3.浮窗权限，您切换不同输入法时可以通过浮窗快捷操作。</p>
    <p>4.震动权限，人设排序时通过震动提示您可以开始排序了。</p>
    <p>5.手机状态,反馈亲Key键盘软件运行状态。</p>
    <p class="text-blue">6.相册、内存卡权限，用于分享功能。</p>
    <p class="text-blue">
      7.软件列表（仅判断特定应用QQ/微信的安装情况），以达到分享的目的
    </p>
    <p class="text-blue">
      8.传感器列表/特定传感器/矢量传感器，打开网页使用（比如，用户协议，隐私协议等所有h5页面）
    </p>
    <p>
      9.我们尊重您的选择权，您可以访问、修改、删除您的个人信息并管理您的授权，我们也为您提供注销、投诉渠道。
    </p>
    <p>
      10.未经您的同意，我们不会私自从第三方获取、共享或对外提供你的个人信息，您也可以随时更正或者删除您的个人信息。
    </p>
  </div>
</template>
<style lang="scss" scoped>
.welcome {
  padding: 30px;
  font-size: 32px;
  line-height: 46px;
  color: var(--main-text-color);
  font-family: PingFang SC;
  text-align: left;
  a,
  .text-blue {
    color: var(--light-text-color);
  }
}
</style>
